import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../services/supabaseClient';
import { useNavigate } from 'react-router-dom';
import {
  Box, Heading, Text, Button, Wrap, Stack
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import MediaKitCard from '../components/MediaKitCard';

export default function ProfilePage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [mediaKitURLPath, setMediaKitURLPath] = useState('');

  useEffect(() => {
    fetchProfile();
  }, [false]);

  const prefillFields = (savedData) => {
    if (savedData.media_kit_path !== null) {
      setMediaKitURLPath(savedData.media_kit_path);
    }
  };

  const fetchProfile = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return;
    }  
  };

  const handleEditMediaKit = async (e) => {
    navigate('/mediakit/form');
  };

  const t1='https://ihmmbczfhccxnfthwwfw.supabase.co/storage/v1/object/sign/media-kits/template-previews/t1.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtZWRpYS1raXRzL3RlbXBsYXRlLXByZXZpZXdzL3QxLnBuZyIsImlhdCI6MTY2NjkxNjE1OSwiZXhwIjoxOTgyMjc2MTU5fQ._CUErb68FzqdmsbSgtZ5dMtpsXZdcOJewXIwY8qRVIQ';
  const t2='https://ihmmbczfhccxnfthwwfw.supabase.co/storage/v1/object/sign/media-kits/template-previews/t2.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtZWRpYS1raXRzL3RlbXBsYXRlLXByZXZpZXdzL3QyLnBuZyIsImlhdCI6MTY2NjkxNjI2MiwiZXhwIjoxOTgyMjc2MjYyfQ.YKRpK4JAjuCoVnXX9jRDkVVuwv-V9yrl-Ed_c8YGoq8';

  const displayMediaKits = () => {
    return (
      <MediaKitCard 
        name="Your MediaKit" 
        image={t1} 
        cta="Update" 
        ctaStyle="solid" 
        ctaHandler={handleEditMediaKit} 
        path={mediaKitURLPath} />
    );
  };

  return (
    <Box w="100%" minH="100vh">
      <Box mx="auto" w="100%" maxW="960px" mt="14">
        <Stack direction={{ base: 'column', md: 'row' }} wrap={true} alignItems="stretch" w="100%">
          <Wrap w="100%" justify="center" spacing={{ base: 12, md: 6 }} px={{ base: 8, md: 0 }}>
            {mediaKitURLPath !== '' ? displayMediaKits() : null}
            <MediaKitCard 
              name="Template" 
              image={t2} 
              cta="Switch" 
              ctaStyle="outline" />
            <MediaKitCard 
              name="Template" 
              image={t2} 
              cta="Switch" 
              ctaStyle="outline" />
          </Wrap>
        </Stack>
      </Box>
    </Box>
  );
}
