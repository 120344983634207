import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  FormControl, Progress, useToast, FormLabel, Box, VStack, Divider, Textarea, Text, Flex, Button, ButtonGroup, Input
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function BioForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  const [bio, setBio] = useState('');
  const [videoURL1, setVideoURL1] = useState('');
  const [videoURL2, setVideoURL2] = useState('');

  useEffect(() => {
    hasPreviousRecord();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('profile');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'bio',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.bio !== null) {
      setBio(savedData.bio);
    }
    if (savedData.video_url_1 !== null) {
      setVideoURL1(savedData.video_url_1);
    }
    if (savedData.video_url_2 !== null) {
      setVideoURL2(savedData.video_url_2);
    }
  };

  const hasPreviousRecord = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return true;
    } 
    return false;
    
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        bio: bio,
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('clientBrands');
      setLoading(false);
    }

  };

  const progress = (2/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Personal Bio</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel mb="0" fontSize='sm'>Background</FormLabel>
            <Text mb="2" color='gray'>Please submit your personal Bio. Keep it brief and interesting.</Text>
            <Textarea value={bio} onChange={(e) => setBio(e.target.value)} placeholder='Bio' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Video URL</FormLabel>
            <Input mb="4" value={videoURL1} onChange={(e) => setVideoURL1(e.target.value)} placeholder='First video URL' size='md' width="100%" />
            <Input value={videoURL2} onChange={(e) => setVideoURL2(e.target.value)} placeholder='Second video URL' size='md' width="100%" /> 
          </FormControl>
    
        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
