import React from 'react';
import HeaderMK from './components/Header';
import SubheaderMK from './components/Subheader';
import BodyTextMK from './components/BodyText';
import { FcAssistant } from 'react-icons/fc';
import { Box, Divider, Flex, Text, Icon, Center, Stack, SimpleGrid, Container } from '@chakra-ui/react';

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Services() {
  return (
    <Box w="100%" backgroundColor={'#DAF8EE'}>
      <Container maxW={'5xl'}>
        <Box py={100}>

          <Center mb={100}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <HeaderMK text={'MY SERVICES'}/>
              <SubheaderMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
            </Stack>
          </Center>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Feature
              icon={<Icon as={FcAssistant} w={10} h={10} />}
              title={'Lifetime Support'}
              text={
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
              }
            />
            <Feature
              icon={<Icon as={FcAssistant} w={10} h={10} />}
              title={'Lifetime Support'}
              text={
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
              }
            />
            <Feature
              icon={<Icon as={FcAssistant} w={10} h={10} />}
              title={'Lifetime Support'}
              text={
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
              }
            />
          </SimpleGrid>
          <Divider py={50}/>
          <BodyTextMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
          <BodyTextMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
        </Box>
      </Container>
    </Box>
  );
}