import React from 'react';
import { HStack, VStack, Input, Textarea, Button, FormControl, FormLabel, InputGroup, Box, Heading, Image, Text, Center, Stack, Container } from '@chakra-ui/react';

const Form = () => {
  return (
    <VStack spacing={5}>
      <HStack spacing={5}>
        <FormControl isRequired>
          <FormLabel color={'white'}>Full Name</FormLabel>
          <InputGroup>
            <Input type="text" name="name" placeholder="Your full name" bg={'white'} />
          </InputGroup>
        </FormControl>

        <FormControl isRequired>
          <FormLabel color={'white'}>Email</FormLabel>
          <InputGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your email address"
              bg={'white'} 
            />
          </InputGroup>
        </FormControl>
      </HStack>

      <HStack spacing={5}>
        <FormControl>
          <FormLabel color={'white'}>Company</FormLabel>
          <InputGroup>
            <Input 
              type="text" 
              name="name" 
              placeholder="Company name" 
              bg={'white'}  />
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel color={'white'}>Phone Number</FormLabel>
          <InputGroup>
            <Input
              type="number"
              name="phone"
              placeholder="Your phone number"
              bg={'white'} 
            />
          </InputGroup>
        </FormControl>
      </HStack>

      <FormControl isRequired>
        <FormLabel color={'white'}>Message</FormLabel>
        <Textarea
          name="text"
          placeholder="Your message"
          rows={6}
          resize="none"
          bg={'white'} 
        />
      </FormControl>

      <Button
        variant="outline"
        color="white">
        SUBMIT
      </Button>
    </VStack>
  );
};

export default function Contact() {
  return (
    <Box w="100%" backgroundColor={'#31A382'}>
      <Container maxW={'5xl'}>
        <Box py={100}>

          <Center mb={50}>
            <Stack color="white" spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <Text fontSize={'md'}>Contact</Text>
              <Heading fontSize={'2.5em'}>Sophie Maria</Heading>
              <Text fontSize={'md'}>Let&apos;s Collaborate</Text>
            </Stack>
          </Center>

          <Center>
            {Form()}
          </Center>

        </Box>
      </Container>
    </Box>
  );
}