import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

export default function PageNotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block" mt="24">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center">
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Media Kit Not Found
      </Heading>
      <Text color={'gray.500'}>
        Please check the URL and try again.
      </Text>
    </Box>
  );
}

