import React from 'react';
import HeaderMK from './components/Header';
import SubheaderMK from './components/Subheader';
import BodyTextMK from './components/BodyText';
import {
  Box,
  Container,
  Center,
  Text,
  Stack,
} from '@chakra-ui/react';

export default function CompanyProfile() {
  return (
    <Container maxW={'5xl'} py={12}>
      <Box py={100}>
        <Center>
          <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
            <HeaderMK text={'Company Profile'}/>
            <SubheaderMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
          </Stack>
        </Center>
      </Box>
    </Container>
  );
}