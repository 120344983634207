import React, { useState, useEffect} from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../services/supabaseClient';
import { VStack, Box } from '@chakra-ui/react';
import NavBar from '../components/Navbar';
import ProfileForm from './forms/1-Profile';
import BioForm from './forms/2-Bio';
import ClientBrandsForm from './forms/3-ClientBrands';
import CompanyBioForm from './forms/5-CompanyBio';
import ServicesForm from './forms/6-Services';
import PhotoGalleryFrom from './forms/7-PhotoGallery';
import TestimonialsForm from './forms/8-Testimonials';
import PressForm from './forms/9-Press';
import SocialForm from './forms/10-Social';

export default function MediaKitForm() {
  const { user } = useAuth();
  const [currentForm, setCurrentForm] = useState(null);

  useEffect(() => {
    fetchLastVisitedForm();
  }, [false]);
  
  const fetchLastVisitedForm = async () => {

    const queryLastVistedPage = await supabase
      .from('media_kits')
      .select('last_visited_form')
      .eq('user_id', user.id);
    
    if (queryLastVistedPage.error) {
      throw queryLastVistedPage.error;
    }

    if (queryLastVistedPage.data[0].last_visited_form !== null) {
      setCurrentForm(queryLastVistedPage.data[0].last_visited_form);
    } else {
      setCurrentForm('profile');
    }
  };

  const displayCurrentPage = () => {

    switch (currentForm) {
    case 'profile':
      return <ProfileForm setCurrentForm={setCurrentForm}/>;
    case 'bio':
      return <BioForm setCurrentForm={setCurrentForm}/>;
    case 'clientBrands':
      return <ClientBrandsForm setCurrentForm={setCurrentForm}/>;
    case 'companyBio':
      return <CompanyBioForm setCurrentForm={setCurrentForm}/>;
    case 'services':
      return <ServicesForm setCurrentForm={setCurrentForm}/>;
    case 'photoGallery':
      return <PhotoGalleryFrom setCurrentForm={setCurrentForm}/>;
    case 'testimonials':
      return <TestimonialsForm setCurrentForm={setCurrentForm}/>;
    case 'press':
      return <PressForm setCurrentForm={setCurrentForm}/>;
    case 'social':
      return <SocialForm setCurrentForm={setCurrentForm}/>;
    default:
      return <ProfileForm setCurrentForm={setCurrentForm}/>;
    }
  };

  return (
    <Box backgroundColor="#F9FBFC;" pb="24">
      <NavBar />
      <VStack spacing={12}>
        {currentForm === null ? null : displayCurrentPage()}
      </VStack>
    </Box>
    
  );
}
