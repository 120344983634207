import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { supabase } from '../services/supabaseClient';
import {
  Flex, Spacer, ButtonGroup, Button, Image
} from '@chakra-ui/react';
import logo from '../assets/logo.png';

export default function NavBar(page) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  supabase.auth.onAuthStateChange((event) => {
    if (event == 'SIGNED_OUT') { 
      navigate('/signup');
      setLoading(false);
    }
  });

  const handleSignOut = async (e) => {
    setLoading(true);
    const { error } = await supabase.auth.signOut();
    if (error)  {
      throw error;
    }
  };

  return (
    <Flex px={{ base: 8 }} justifyContent="center" borderBottom='1px' borderBottomColor='#f7f7f7' boxShadow="xs" bg='white'>
      <Flex mx={{sm: 6}} h="20" maxW="960px" w="100%" alignItems="center">
        <Image maxW="75" objectFit="contain" src={logo} />
        <Spacer />
        <ButtonGroup spacing='6'>
          <Button isLoading={loading} colorScheme='blue' variant='link' onClick={handleSignOut}>Logout</Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
