import React, { useState, useEffect } from 'react';
import { supabase } from '../services/supabaseClient';
import { useNavigate } from 'react-router-dom';
import {
  Heading, useToast, Image, Text, Flex, Stack, InputLeftElement, InputGroup, Box, Input, InputRightElement, Button
} from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png';

export default function SignupPage() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [show, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const handleClick = () => setShowPassword(!show);

  const checkPassword = () => { 
    if (password.length < 6) {
      toast({
        title: 'Password must be at least 6 characters long',
        status: 'error',
        isClosable: true,
        position: 'top'
      });
      setLoading(false);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (checkPassword() === false) return;

    const { error } = await supabase.auth.updateUser({
      password: password
    });

    if (error) {
      toast({
        title: `${error.message}`,
        status: 'error',
        isClosable: true,
        position: 'top'
      });
      setLoading(false);
      return;
    }

    toast({
      title: 'Password updated',
      status: 'success',
      isClosable: true,
      position: 'top'
    });
    navigate('/dashboard');
  };

  return (
    <Flex backgroundColor="#F9FBFC;" direction="column" minH="100vh" h="100%">
      <Flex mt="24" mb="8" px="6" h="20" alignItems="center" justifyContent="center">
        <Image maxW="100" objectFit="contain" src={logo} />
      </Flex>
      <Flex direction="column" alignItems="center" >
        <Box minWidth={{ base: '100%', md: '511px' }} bg="white" maxW='lg' px="12" py="12" borderWidth='1px' borderRadius='lg' overflow='hidden'>
          <Flex direction="column" mb="14">
            <Heading size='lg' mb="4">
              Reset Password
            </Heading>
            <Text fontSize='lg'>Enter your new password</Text>
          </Flex>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftElement
                  h="100%"
                  pointerEvents='none'
                  color='gray.300'
                  fontSize='1.2em'
                  children={<LockIcon color='gray.300' />}
                />
                <Input 
                  id="password"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Enter your new password' 
                  size="lg"
                />
                <InputRightElement h="100%" width='4.5rem'>
                  <Button size='md' mr="1" onClick={handleClick}>
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Button 
              isLoading={loading}
              type="submit"
              spinnerPlacement='start'
              colorScheme="blue"
              w='full'
              size='lg'
              mt="8"
            >
                  Update password
            </Button>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
}
