import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import { useNavigate } from 'react-router-dom';
import {
  Progress, FormControl, useToast, FormLabel, Box, VStack, Input, Divider, Text, Flex, Button, ButtonGroup
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function PressForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();

  const [instagramURL, setInstagramURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');
  const [linkedinURL, setLinkedinURL] = useState('');
  const [followerCount, setFollowerCount] = useState('');

  useEffect(() => {
    hasPreviousRecord();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('press');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'stats',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.instagram_url !== null) {
      setInstagramURL(savedData.instagram_url);
    }
    if (savedData.twitter_url !== null) {
      setTwitterURL(savedData.twitter_url);
    }
    if (savedData.facebook_url !== null) {
      setFacebookURL(savedData.facebook_url);
    }
    if (savedData.youtube_url !== null) {
      setYoutubeURL(savedData.youtube_url);
    }
    if (savedData.linkedin_url !== null) {
      setLinkedinURL(savedData.linkedin_url);
    }
    if (savedData.follower_count !== null) {
      setFollowerCount(savedData.follower_count);
    }
  };

  const hasPreviousRecord = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return true;
    } 
    return false;
    
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        instagram_url: instagramURL,
        twitter_url: twitterURL,
        facebook_url: facebookURL,
        youtube_url: youtubeURL,
        linkedin_url: linkedinURL,
        follower_count: followerCount
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('profile');
      navigate('/dashboard');
      setLoading(false);
    }

  };

  const progress = (10/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Social</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Audience Size</FormLabel>
            <Input value={followerCount} onChange={(e) => setFollowerCount(e.target.value)} placeholder='Audience size' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Instagram URL</FormLabel>
            <Input value={instagramURL} onChange={(e) => setInstagramURL(e.target.value)} placeholder='Instagram URL' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Twitter URL</FormLabel>
            <Input value={twitterURL} onChange={(e) => setTwitterURL(e.target.value)} placeholder='Twitter URL' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Facebook URL</FormLabel>
            <Input value={facebookURL} onChange={(e) => setFacebookURL(e.target.value)} placeholder='Facebook URL' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Youtube URL</FormLabel>
            <Input value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} placeholder='Youtube URL' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Linkedin URL</FormLabel>
            <Input value={linkedinURL} onChange={(e) => setLinkedinURL(e.target.value)} placeholder='Linkedin URL' size='md' width="100%" />
          </FormControl>

        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Complete 🎉</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
