import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import Hero from '../../components/mediaKits/general/Hero';
import Profile from '../../components/mediaKits/general/Profile';
import ClientBrands from '../../components/mediaKits/general/ClientBrands';
import Video from '../../components/mediaKits/general/Video';
import CompanyProfile from '../../components/mediaKits/general/CompanyProfile';
import Services from '../../components/mediaKits/general/Services';
import Gallery from '../../components/mediaKits/general/Gallery';
import Testimonials from '../../components/mediaKits/general/Testimonials';
import Press from '../../components/mediaKits/general/Press';
import Social from '../../components/mediaKits/general/Social';
import Contact from '../../components/mediaKits/general/Contact';
import Footer from '../../components/mediaKits/general/Footer';

export default function One() {
  const navigate = useNavigate();
  const [mediaKitPath, setMediaKitPath] = useState('');
  const [mediaKitData, setMediaKitData] = useState(null);

  useEffect(() => {
    fetchMediaKitInfo();
  }, [mediaKitPath]);

  const handleNotFound = () => {
    navigate('/404');
  };

  const checkURLPath = async () => {
    const mediaURLPath = window.location.pathname;
    const filterURLPath = mediaURLPath.match(/kit\/(.*)/)[1];
    setMediaKitPath(filterURLPath);
  };

  const fetchMediaKitInfo = async (e) => {
    await checkURLPath();

    if (mediaKitPath !== null) {
      const URLPaths = await supabase
        .from('media_kits')
        .select()
        .match({media_kit_path: mediaKitPath});

      if (URLPaths.error) {
        throw URLPaths.error;
      } 

      if (URLPaths.data.length > 0) {
      
        console.log(URLPaths.data[0]);
        
        // add each property as state
        // setMediaKitData(URLPaths.data[0].subdomain);

        return true;
      } 
    }

    // handleNotFound();
    return false;
  };

  return (
    <>
      <Hero />
      <Profile />
      <ClientBrands />
      <Video />
      <CompanyProfile />
      <Services />
      <Gallery />
      <Testimonials />
      <Press />
      <Social />
      <Contact />
      <Footer/>
    </>
  );
}