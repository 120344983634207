import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  Progress, FormControl, InputGroup, InputLeftAddon, useToast, FormLabel, Box, VStack, Input, Text, Divider, Button, Flex, HStack, ButtonGroup
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function ProfileForm({setCurrentForm}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [website, setWebsite] = useState('');

  useEffect(() => {
    fetchRecords();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    navigate('/dashboard');
    setLoadingBack(false);
  };
  
  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'profile',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {
    if (savedData.first_name !== null) {
      setFirstName(savedData.first_name);
    }
    if (savedData.last_name !== null) {
      setLastName(savedData.last_name);
    }
    if (savedData.phone_number !== null) {
      setPhoneNumber(savedData.phone_number);
    }
    if (savedData.company_name !== null) {
      setCompanyName(savedData.company_name);
    }
    if (savedData.email_address !== null) {
      setEmailAddress(savedData.email_address);
    }
    if (savedData.website !== null) {
      setWebsite(savedData.website);
    }
  };

  const fetchRecords = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return;
    }  
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        company_name: companyName,
        email_address: emailAddress,
        website: website,
        media_kit_path: firstName + '-' + lastName
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('bio');
      setLoading(false);
    }
  };

  const progress = (1/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Personal Info</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">
            
          <HStack w="100%" spacing={6}>
            <FormControl>
              <FormLabel fontSize='sm' mb="2">First Name</FormLabel>
              <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='First name' size='md' width="100%" />
            </FormControl>
            <FormControl>
              <FormLabel fontSize='sm' mb="2">Last Name</FormLabel>
              <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Last name' size='md' width="100%" />
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Company Name</FormLabel>
            <Input value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder='Company name' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Email</FormLabel>
            <Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder='Email address' size='md' width="100%" />
          </FormControl>
         
          <FormControl>
            <Flex direction="column">
              <FormLabel fontSize='sm' mb="2">Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+1' />
                <Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type='tel' placeholder='Phone number' size='md' width="100%" />
              </InputGroup>
            </Flex>
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Website</FormLabel>
            <InputGroup size='md'>
              <InputLeftAddon children='https://' />
              <Input value={website} onChange={(e) => setWebsite(e.target.value)} placeholder='Website' size='md' width="100%" />
            </InputGroup>
          </FormControl>

        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="3" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Cancel</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>

  );
}
