import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  Progress, FormControl, useToast, FormLabel, Box, VStack, Input, Divider, Text, Flex, Button, ButtonGroup
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function PressForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  const [pressTitle1, setPressTitle1] = useState('');
  const [pressURL1, setPressURL1] = useState('');
  const [pressDescription1, setPressDescription1] = useState('');
  const [pressTitle2, setPressTitle2] = useState('');
  const [pressURL2, setPressURL2] = useState('');
  const [pressDescription2, setPressDescription2] = useState('');
  const [pressTitle3, setPressTitle3] = useState('');
  const [pressURL3, setPressURL3] = useState('');
  const [pressDescription3, setPressDescription3] = useState('');

  useEffect(() => {
    hasPreviousRecord();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('testimonials');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'press',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.press_title1 !== null) {
      setPressTitle1(savedData.press_title1);
    }
    if (savedData.press_url1 !== null) {
      setPressURL1(savedData.press_url1);
    }
    if (savedData.press_description1 !== null) {
      setPressDescription1(savedData.press_description1);
    }
    if (savedData.press_title2 !== null) {
      setPressTitle2(savedData.press_title2);
    }
    if (savedData.press_url2 !== null) {
      setPressURL2(savedData.press_url2);
    }
    if (savedData.press_description2 !== null) {
      setPressDescription2(savedData.press_description2);
    }
    if (savedData.press_title3 !== null) {
      setPressTitle3(savedData.press_title3);
    }
    if (savedData.press_url3 !== null) {
      setPressURL3(savedData.press_url3);
    }
    if (savedData.press_description3 !== null) {
      setPressDescription3(savedData.press_description3);
    }
  };

  const hasPreviousRecord = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return true;
    } 
    return false;
    
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        press_title1: pressTitle1,
        press_url1: pressURL1,
        press_description1: pressDescription1,
        press_title2: pressTitle2,
        press_url2: pressURL2,
        press_description2: pressDescription2,
        press_title3: pressTitle3,
        press_url3: pressURL3,
        press_description3: pressDescription3
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('social');
      setLoading(false);
    }

  };

  const progress = (9/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Press Releases</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Press Release 1</FormLabel>
            <Input mb="4" value={pressTitle1} onChange={(e) => setPressTitle1(e.target.value)} placeholder='Press title' size='md' width="100%" />
            <Input mb="4" value={pressURL1} onChange={(e) => setPressURL1(e.target.value)} placeholder='Press URL' size='md' width="100%" />
            <Input value={pressDescription1} onChange={(e) => setPressDescription1(e.target.value)} placeholder='Press descirption' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Press Release 2</FormLabel>
            <Input mb="4" value={pressTitle2} onChange={(e) => setPressTitle2(e.target.value)} placeholder='Press title' size='md' width="100%" />
            <Input mb="4" value={pressURL2} onChange={(e) => setPressURL2(e.target.value)} placeholder='Press URL' size='md' width="100%" />
            <Input value={pressDescription2} onChange={(e) => setPressDescription2(e.target.value)} placeholder='Press descirption' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Press Release 3</FormLabel>
            <Input mb="4" value={pressTitle3} onChange={(e) => setPressTitle3(e.target.value)} placeholder='Press title' size='md' width="100%" />
            <Input mb="4" value={pressURL3} onChange={(e) => setPressURL3(e.target.value)} placeholder='Press URL' size='md' width="100%" />
            <Input value={pressDescription3} onChange={(e) => setPressDescription3(e.target.value)} placeholder='Press descirption' size='md' width="100%" />
          </FormControl>

        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
