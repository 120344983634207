import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  FormControl, Icon, Grid, Image, Progress, useToast, Heading, Box, Stack, Divider, Text, Flex, Button, ButtonGroup, VStack
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function ClientBrandsForm({setCurrentForm}) {
  const { user } = useAuth();
  const toast = useToast();
   
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [userIdentifier, setUserIdentifier] = useState(null);

  const brandLogoOneFileInput = useRef();
  const [isBrandLogoOneFileSelected, setIsBrandLogoOneFileSelected] = useState(false);
  const [isBrandLogoOneUploaded, setIsBrandLogoOneUploaded] = useState(false);
  const [loadingBrandLogoOne, setLoadingBrandLogoOne] = useState(null);
  const [brandLogoOneImageURL, setBrandLogoOneImageURL] = useState(null);

  const brandLogoTwoFileInput = useRef();
  const [isBrandLogoTwoFileSelected, setIsBrandLogoTwoFileSelected] = useState(false);
  const [isBrandLogoTwoUploaded, setIsBrandLogoTwoUploaded] = useState(false);
  const [brandLogoTwoImageURL, setBrandLogoTwoImageURL] = useState(null);
  
  useEffect(() => {
    fetchUserRecords();
    setCurrentPage();
  }, [false]);

  useEffect(() => {
    if (userIdentifier !== null) {
      fetchFiles();
    }
  }, [userIdentifier, brandLogoOneImageURL, brandLogoTwoImageURL]);

  const fetchUserRecords = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      const savedData = querySavedData.data[0];
      const userData = `${savedData.first_name}-${savedData.last_name}-${savedData.company_name}`;
      setUserIdentifier(userData);
      return true;
    } 
    return false;
    
  };

  const fetchFiles = async () => {
    const brandLogoOneData = await supabase.storage
      .from('media-kits')
      .createSignedUrl(userIdentifier + '/BrandLogoOne', 86400);
    if (brandLogoOneData.error) throw brandLogoOneData.error;
    if (brandLogoOneData.data) setBrandLogoOneImageURL(brandLogoOneData.data.signedUrl);
    console.log(brandLogoOneImageURL);
    console.log(brandLogoOneData);

    const brandLogoTwoData = await supabase.storage
      .from('media-kits')
      .createSignedUrl(userIdentifier + '/BrandLogoTwo', 86400);
    if (brandLogoTwoData.error) throw brandLogoTwoData.error;
    if (brandLogoTwoData.data) setBrandLogoTwoImageURL(brandLogoTwoData.data.signedUrl);
   
  };
  
  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('bio');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'clientBrands',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const selectFileHandler = (input) => {
    input.current.click();
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);
    showAlert('Progress saved', 'success');
    setCurrentForm('companyBio');
  };

  // files

  const brandLogoOneChangeHandler = async (e) => {
    setIsBrandLogoOneFileSelected(true);
    handleBrandLogoOneSubmission(e.target.files[0]);
  };

  const handleBrandLogoOneSubmission = async (selectedBrandLogoOneFile) => {
    const { error } = await supabase.storage
      .from('media-kits')
      .upload(userIdentifier + '/BrandLogoOne', selectedBrandLogoOneFile, {
        cacheControl: '3600',
        upsert: true
      });

    if (error) {
      showAlert(`Error uploading file: ${error.message}`, 'error');
      throw error;
    } else {
      setIsBrandLogoOneUploaded(true);
      showAlert('File uploaded', 'success');
    }
  };

  const isBrandLogoOneSelected = () => {
    if (isBrandLogoOneFileSelected) {
      return (
        <Stack mt="4" direction='row' spacing={4} alignItems="center">
          {isBrandLogoOneUploaded ? 
            (<Text color="teal">Upload Complete</Text>
            ) : <Text color="orange">Loading...</Text>}
        </Stack>
      );
    } 
    return (
      <Stack mt="4" direction='row' spacing={0} alignItems="center">
        <input style={{display: 'none'}} type="file" name="file" ref={brandLogoOneFileInput} onChange={brandLogoOneChangeHandler} />
        <Button onClick={() => selectFileHandler(brandLogoOneFileInput)} type="file" name="file" colorScheme='blue' variant='outline'>
                  Select a file
        </Button>
      </Stack>
    );
  };

  const brandLogoTwoChangeHandler = async (e) => {
    setIsBrandLogoTwoFileSelected(true);
    handleBrandLogoTwoSubmission(e.target.files[0]);
  };

  const handleBrandLogoTwoSubmission = async (selectedBrandLogoTwoFile) => {
    const { error } = await supabase.storage
      .from('media-kits')
      .upload(userIdentifier + '/BrandLogoTwo', selectedBrandLogoTwoFile, {
        cacheControl: '3600',
        upsert: true
      });

    if (error) {
      showAlert(`Error uploading file: ${error.message}`, 'error');
      throw error;
    } else {
      setIsBrandLogoTwoUploaded(true);
      showAlert('File uploaded', 'success');
    }
  };

  const isBrandLogoTwoSelected = () => {
    if (isBrandLogoTwoFileSelected) {
      return (
        <Stack mt="4" direction='row' spacing={4} alignItems="center">
          {isBrandLogoTwoUploaded ? 
            (<Text color="teal">Upload Complete</Text>
            ) : <Text color="orange">Loading...</Text>}
        </Stack>
      );
    } 
    return (
      <Stack mt="4" direction='row' spacing={0} alignItems="center">
        <input style={{display: 'none'}} type="file" name="file" ref={brandLogoTwoFileInput} onChange={brandLogoTwoChangeHandler} />
        <Button onClick={() => selectFileHandler(brandLogoTwoFileInput)} type="file" name="file" colorScheme='blue' variant='outline'>
                  Select a file
        </Button>
      </Stack>
    );
  };

  const progress = (3/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Client Brands</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
    
        <Grid templateColumns='repeat(3, 1fr)' gap={6} textAlign="left">
         
          <Box>
            <Heading mb="4" fontSize='sm'>Brand Logo 1</Heading>
            <Box borderColor="gray.100" borderWidth="1px">
              <Image h="200" w="200" objectFit="cover" src={brandLogoOneImageURL} />
            </Box>  
            {isBrandLogoOneSelected()}
          </Box>

          <Box>
            <Heading mb="4" fontSize='sm'>Brand Logo 2</Heading>
            <Box borderColor="gray.100" borderWidth="1px">
              <Image h="200" w="200" objectFit="cover" src={brandLogoTwoImageURL} />
            </Box>  
            {isBrandLogoTwoSelected()}
          </Box>

        </Grid>

      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
