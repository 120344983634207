import React from 'react';
import HeaderMK from './components/Header';
import SubheaderMK from './components/Subheader';
import {
  Box,
  Container,
  Center,
  Stack,
} from '@chakra-ui/react';

export default function Gallery() {
  return (
    <Box w="100%">
      <Container maxW={'5xl'}>
        <Box py={100}>

          <Center mb={100}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <HeaderMK text={'GALLERY'}/>
              <SubheaderMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
            </Stack>
          </Center>

        </Box>
      </Container>
    </Box>
  );
}