import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  Progress, FormControl, useToast, FormLabel, Box, VStack, Input, Divider, Text, Flex, Button, ButtonGroup, Textarea
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function TestimonialsForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  const [testimonial1, setTestimonial1] = useState('');
  const [reviewerName1, setReviewerName1] = useState('');
  const [reviewerTitle1, setReviewerTitle1] = useState('');
  const [reviewerImageURL1, setReviewerImageURL1] = useState('');
  const [testimonial2, setTestimonial2] = useState('');
  const [reviewerName2, setReviewerName2] = useState('');
  const [reviewerTitle2, setReviewerTitle2] = useState('');
  const [reviewerImageURL2, setReviewerImageURL2] = useState('');
  const [testimonial3, setTestimonial3] = useState('');
  const [reviewerName3, setReviewerName3] = useState('');
  const [reviewerTitle3, setReviewerTitle3] = useState('');
  const [reviewerImageURL3, setReviewerImageURL3] = useState('');

  useEffect(() => {
    hasPreviousRecord();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('photoGallery');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'testimonials',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.testimonial1 !== null) {
      setTestimonial1(savedData.testimonial1);
    }
    if (savedData.reviewer_name1 !== null) {
      setReviewerName1(savedData.reviewer_name1);
    }
    if (savedData.reviewer_title1 !== null) {
      setReviewerTitle1(savedData.reviewer_title1);
    }
    if (savedData.reviewer_image_url1 !== null) {
      setReviewerImageURL1(savedData.reviewer_image_url1);
    }
    if (savedData.testimonial2 !== null) {
      setTestimonial2(savedData.testimonial2);
    }
    if (savedData.reviewer_name2 !== null) {
      setReviewerName2(savedData.reviewer_name2);
    }
    if (savedData.reviewer_title2 !== null) {
      setReviewerTitle2(savedData.reviewer_title2);
    }
    if (savedData.reviewer_image_url2 !== null) {
      setReviewerImageURL2(savedData.reviewer_image_url2);
    }
    if (savedData.testimonial3 !== null) {
      setTestimonial3(savedData.testimonial3);
    }
    if (savedData.reviewer_name3 !== null) {
      setReviewerName3(savedData.reviewer_name3);
    }
    if (savedData.reviewer_title3 !== null) {
      setReviewerTitle3(savedData.reviewer_title3);
    }
    if (savedData.reviewer_image_url3 !== null) {
      setReviewerImageURL3(savedData.reviewer_image_url3);
    }

  };

  const hasPreviousRecord = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return true;
    } 
    return false;
    
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        testimonial1: testimonial1,
        reviewer_name1: reviewerName1,
        reviewer_title1: reviewerTitle1,
        reviewer_image_url1: reviewerImageURL1,
        testimonial2: testimonial2,
        reviewer_name2: reviewerName2,
        reviewer_title2: reviewerTitle2,
        reviewer_image_url2: reviewerImageURL2,
        testimonial3: testimonial3,
        reviewer_name3: reviewerName3,
        reviewer_title3: reviewerTitle3,
        reviewer_image_url3: reviewerImageURL3
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('press');
      setLoading(false);
    }

  };

  const progress = (8/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Testimonials</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Testimonial 1</FormLabel>
            <Input mb="4" value={reviewerName1} onChange={(e) => setReviewerName1(e.target.value)} placeholder='Reviewer name' size='md' width="100%" />
            <Input mb="4" value={reviewerTitle1} onChange={(e) => setReviewerTitle1(e.target.value)} placeholder='Reviewer title' size='md' width="100%" />
            <Input mb="4" value={reviewerImageURL1} onChange={(e) => setReviewerImageURL1(e.target.value)} placeholder='Image URL' size='md' width="100%" />
            <Textarea value={testimonial1} onChange={(e) => setTestimonial1(e.target.value)} placeholder='Testimonial' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Testimonial 2</FormLabel>
            <Input mb="4" value={reviewerName2} onChange={(e) => setReviewerName2(e.target.value)} placeholder='Reviewer name' size='md' width="100%" />
            <Input mb="4" value={reviewerTitle2} onChange={(e) => setReviewerTitle2(e.target.value)} placeholder='Reviewer title' size='md' width="100%" />
            <Input mb="4" value={reviewerImageURL2} onChange={(e) => setReviewerImageURL2(e.target.value)} placeholder='Image URL' size='md' width="100%" />
            <Textarea value={testimonial2} onChange={(e) => setTestimonial2(e.target.value)} placeholder='Testimonial' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Testimonial 3</FormLabel>
            <Input mb="4" value={reviewerName3} onChange={(e) => setReviewerName3(e.target.value)} placeholder='Reviewer name' size='md' width="100%" />
            <Input mb="4" value={reviewerTitle3} onChange={(e) => setReviewerTitle3(e.target.value)} placeholder='Reviewer title' size='md' width="100%" />
            <Input mb="4" value={reviewerImageURL3} onChange={(e) => setReviewerImageURL3(e.target.value)} placeholder='Image URL' size='md' width="100%" />
            <Textarea value={testimonial3} onChange={(e) => setTestimonial3(e.target.value)} placeholder='Testimonial' size='md' width="100%" />
          </FormControl>
    
        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
