import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../services/supabaseClient';
import {
  Flex, Heading, Text, Box, Button
} from '@chakra-ui/react';

export default function MediaKitCollection() {
  const [loading, setLoading] = useState(false);
  
  return (
    <Flex backgroundColor="#F9FBFC;" direction="column" minH="100vh" h="100%">
      <Flex mt="24" direction="column" alignItems="center" >
        <Box bg="white" maxW='lg' px="12" py="12" borderWidth='1px' borderRadius='lg' overflow='hidden'>
          <Flex direction="column" mb="8">
            <Heading size='lg' mb="4">
              Collection of completed media kits
            </Heading>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
