import React, { useEffect, useState }from 'react';
import NavBar from '../components/Navbar';
import {
  Box, Flex, Heading, Button, Text, Image, Link
} from '@chakra-ui/react';

export default function MediaKitCard({ name, image, path, cta, ctaStyle, ctaHandler }) {

  const imageHeight = () => {
    if (cta != 'Switch') {
      return '300px';
    } 
    return '374.8px';
  };

  return (
    <Box minW={{ md: '290px' }} w={{ base: '100%', md: '290px' }} h="100%" borderWidth='1px' borderRadius='lg' overflow='hidden' alignItems="left">

      <Box p='6'>
        <Flex justifyContent="space-between" alignItems='center'>
          <Heading mr="8" fontSize="lg">{name}</Heading> 
          <Button onClick={ctaHandler} colorScheme='blue' variant={ctaStyle}>{cta}</Button>
        </Flex>
      </Box>
      
      <Link href={'/kit/' + path}>
        <Image h="100%" w="100%" maxH={imageHeight} objectFit='cover' objectPosition="top" src={image}/>
      </Link>

      {path !== undefined ? (
        
        <Box px="6" py="4" w="100%" background="gray.100" textAlign="left">
          <Heading fontSize='sm'>Media Kit URL</Heading>
          <Link href={'/kit/' + path}>
            <Text color="blue.500">{'digitalmediakit.co/kit/' + path}</Text>
          </Link>
        </Box>
        
      ) : null}
      
    </Box>

  );
}