import React from 'react';
import {  Box, Divider, Image, Text, Center, Stack, Container } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box w="100%">
      <Container maxW={'5xl'}>
        <Box py={50}>
          <Container>
            <Divider mb={4} />
          </Container>
          <Center>
            <Text fontSize={'xs'}>© All rights Reserved by DigiMediaKit LLC | Made with ❤️</Text>
          </Center>

        </Box>
      </Container>
    </Box>
  );
}