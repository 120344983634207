import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  FormControl, Progress, useToast, FormLabel, Image, Stack, Box, Textarea, VStack, Input, Divider, Text, Flex, Button, ButtonGroup
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function CompanyBioForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  const [companyBio, setCompanyBio] = useState('');
  const [userIdentifier, setUserIdentifier] = useState(null);

  const CompanyLogoInput = useRef();
  const [isCompanyLogoSelected, setIsCompanyLogoSelected] = useState(false);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [companyLogoImageURL, setCompanyLogoImageURL] = useState(null);

  useEffect(() => {
    fetchUserRecords();
    setCurrentPage();
  }, [false]);

  useEffect(() => {
    if (userIdentifier !== null) {
      fetchFiles();
    }
  }, [userIdentifier, companyLogoImageURL]);

  const fetchUserRecords = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      const savedData = querySavedData.data[0];
      const userData = `${savedData.first_name}-${savedData.last_name}-${savedData.company_name}`;
      setUserIdentifier(userData);
      return true;
    } 
    return false;
  };

  const fetchFiles = async () => {
    const VideoData = await supabase.storage
      .from('media-kits')
      .createSignedUrl(userIdentifier + '/video', 86400);
    if (VideoData.error) throw VideoData.error;
    if (VideoData.data) setCompanyLogoImageURL(VideoData.data.signedUrl);
  };

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('clientBrands');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'companyBio',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.company_bio !== null) {
      setCompanyBio(savedData.company_bio);
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        company_bio: companyBio,
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('services');
      setLoading(false);
    }

  };

  const selectFileHandler = (input) => {
    input.current.click();
  };

  const VideoChangeHandler = async (e) => {
    setIsCompanyLogoSelected(true);
    handleVideoSubmission(e.target.files[0]);
  };

  const handleVideoSubmission = async (selectedCompanyLogo) => {
    const { error } = await supabase.storage
      .from('media-kits')
      .upload(userIdentifier + '/companyLogo', selectedCompanyLogo, {
        cacheControl: '3600',
        upsert: true
      });

    if (error) {
      showAlert(`Error uploading file: ${error.message}`, 'error');
      throw error;
    } else {
      setIsVideoUploaded(true);
      showAlert('File uploaded', 'success');
    }
  };

  const isVideoSelected = () => {
    if (isCompanyLogoSelected) {
      return (
        <Stack mt="4" direction='row' spacing={4} alignItems="center">
          {isVideoUploaded ? 
            (<Text color="teal">Upload Complete</Text>
            ) : <Text color="orange">Loading...</Text>}
        </Stack>
      );
    } 
    return (
      <Stack mt="4" direction='row' spacing={0} alignItems="center">
        <input style={{display: 'none'}} type="file" name="file" ref={CompanyLogoInput} onChange={VideoChangeHandler} />
        <Button onClick={() => selectFileHandler(CompanyLogoInput)} type="file" name="file" colorScheme='blue' variant='outline'>
                  Select a file
        </Button>
      </Stack>
    );
  };

  const progress = (5/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Company Bio</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Company Bio</FormLabel>
            <Textarea value={companyBio} onChange={(e) => setCompanyBio(e.target.value)} placeholder='Company bio' size='md' width="100%" />
          </FormControl>
          
          {/* <Box>
            <Box borderColor="gray.100" borderWidth="1px">
              <Image h="200" w="200" objectFit="cover" src={companyLogoImageURL} />
            </Box>  
            {isVideoSelected()}
          </Box> */}
    
        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
