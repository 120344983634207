import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import MediaKitForm from './pages/MediaKitForm';
import NotFound from './pages/PageNotFound';
import General from './pages/templates/General';
import MediaKitCollection from './pages/MediaKitCollection';
import LandingPage from './pages/LandingPage';
import PasswordRecovery from './pages/PasswordRecovery';

function App() {
  return (
    <div className="App">
      <Router>
        <Fragment>
          <AuthProvider>
            <Routes>
              <Route exact path="/" element={<LandingPage />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/404" element={<NotFound />} />
              <Route exact path="/reset" element={<PasswordRecovery />} />
              <Route
                element={<General />}
                path="/kit/:kitpath"
              />
              <Route element={<PrivateRoute />}>
                <Route exact path='/mediakit/form' element={<MediaKitForm />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path='/dashboard' element={<Dashboard />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route exact path='/collections' element={<MediaKitCollection />} />
              </Route>
            </Routes>
          </AuthProvider>
        </Fragment>
      </Router>
    </div >
  );
}

export default App;
