import React from 'react';
import { Box, Center, Container } from '@chakra-ui/react';

export default function Video() {

  return (
    <Box w="100%" backgroundColor={'#DAF8EE'}>
      <Container maxW={'5xl'}>
        <Box py={100}>
          <Center>  
            <div className="video">
              <div className="video__container">
                <div className="video__container--video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/8h7p88oySWY"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </Center>
        </Box>
      </Container>
    </Box>
    
  );
}