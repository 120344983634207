import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../services/supabaseClient';
import {
  Progress, FormControl, useToast, FormLabel, Box, VStack, Input, Divider, Text, Flex, Button, ButtonGroup
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function ServicesForm({setCurrentForm}) {
  const [loading, setLoading] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  const [serviceTitle1, setServiceTitle1] = useState('');
  const [serviceDescription1, setServiceDescription1] = useState('');
  const [serviceTitle2, setServiceTitle2] = useState('');
  const [serviceDescription2, setServiceDescription2] = useState('');
  const [serviceTitle3, setServiceTitle3] = useState('');
  const [serviceDescription3, setServiceDescription3] = useState('');

  useEffect(() => {
    hasPreviousRecord();
    setCurrentPage();
  }, [false]);

  const showAlert = (message, status) => {
    toast({
      title: `${message}`,
      status: `${status}`,
      isClosable: true,
      position: 'top'
    });
  };

  const handleNavigateBack = () => {
    setLoadingBack(true);
    setCurrentForm('companyBio');
    setLoadingBack(false);
  };

  const setCurrentPage = async () => { 
    const { error } = await supabase
      .from('media_kits')
      .update({ 
        last_visited_form: 'services',
      })
      .match({ user_id: user.id });

    if (error) {
      throw error;
    }
  };

  const prefillFields = (savedData) => {

    if (savedData.service_title_1 !== null) {
      setServiceTitle1(savedData.service_title_1);
    }
    if (savedData.service_description_1 !== null) {
      setServiceDescription1(savedData.service_description_1);
    }
    if (savedData.service_title_2 !== null) {
      setServiceTitle2(savedData.service_title_2);
    }
    if (savedData.service_description_2 !== null) {
      setServiceDescription2(savedData.service_description_2);
    }
    if (savedData.service_title_3 !== null) {
      setServiceTitle3(savedData.service_title_3);
    }
    if (savedData.service_description_3 !== null) {
      setServiceDescription3(savedData.service_description_3);
    }
  };

  const hasPreviousRecord = async (e) => {

    const querySavedData = await supabase
      .from('media_kits')
      .select('*')
      .eq('user_id', user.id);

    if (querySavedData.error) {
      throw querySavedData.error;
    } 

    if (querySavedData.data.length > 0) {
      prefillFields(querySavedData.data[0]);
      return true;
    } 
    return false;
    
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase
      .from('media_kits')
      .update({ 
        service_title_1: serviceTitle1,
        service_description_1: serviceDescription1,
        service_title_2: serviceTitle2,
        service_description_2: serviceDescription2,
        service_title_3: serviceTitle3,
        service_description_3: serviceDescription3,
      })
      .match({ user_id: user.id });

    if (error) {
      showAlert(`error saving: ${error}`, 'error');
      throw error;
    } else {
      showAlert('Progress saved', 'success');
      setCurrentForm('photoGallery');
      setLoading(false);
    }

  };

  const progress = (6/10) * 100;

  return (
    <Box w="100%" minH="100vh">
      <Progress hasStripe w='100%' colorScheme='blue' size='md' value={progress} />
      <Box p={6} bg="white" mx="auto" borderRadius="lg" borderWidth='1px' w="100%" maxW="960px" mt="14">
        <Text mb="6" textAlign="left" fontSize='mg' fontWeight='bold'>Services</Text> 
        <Divider orientation='horizontal' mb="6" w="100%"/>
        <VStack spacing={6} textAlign="left">

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Service 1</FormLabel>
            <Input mb="4" value={serviceTitle1} onChange={(e) => setServiceTitle1(e.target.value)} placeholder='Service title' size='md' width="100%" />
            <Input value={serviceDescription1} onChange={(e) => setServiceDescription1(e.target.value)} placeholder='Service description' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Service 2</FormLabel>
            <Input mb="4" value={serviceTitle2} onChange={(e) => setServiceTitle2(e.target.value)} placeholder='Service title' size='md' width="100%" />
            <Input value={serviceDescription2} onChange={(e) => setServiceDescription2(e.target.value)} placeholder='Service description' size='md' width="100%" />
          </FormControl>

          <FormControl>
            <FormLabel fontSize='sm' mb="2">Service 3</FormLabel>
            <Input mb="4" value={serviceTitle3} onChange={(e) => setServiceTitle3(e.target.value)} placeholder='Service title' size='md' width="100%" />
            <Input value={serviceDescription3} onChange={(e) => setServiceDescription3(e.target.value)} placeholder='Service description' size='md' width="100%" />
          </FormControl>
    
        </VStack>
      </Box>
      <Flex w="100%" mt="6">
        <Box textAlign="right" mt="6" w="100%" maxW="960px" mx="auto">
          <ButtonGroup spacing='3'>
            <Button onClick={handleNavigateBack} isLoading={loadingBack} variant='outline' minW="100px" colorScheme='blue'>Back</Button>
            <Button onClick={handleContinue} isLoading={loading} rightIcon={<ArrowForwardIcon />} colorScheme='blue'>Continue</Button>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
}
