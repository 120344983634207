import React from 'react';
import HeaderMK from './components/Header';
import SubheaderMK from './components/Subheader';
import { Box, Heading, Image, Text, Center, Stack, SimpleGrid, Container } from '@chakra-ui/react';

const logoURL = 'https://cdn-icons-png.flaticon.com/512/87/87390.png';

export default function Social() {
  return (
    <Box w="100%" backgroundColor={'#FAFAFA'}>
      <Container maxW={'5xl'}>
        <Box py={100}>

          <Center mb={100}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <HeaderMK text={'PRESS / PODCASTS'}/>
              <SubheaderMK text='Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.' />
            </Stack>
          </Center>

          <Center>
            <SimpleGrid columns={{ base: 1, md: 5 }} spacing={10}>
              <Image maxW={'20px'} src={logoURL}/>
              <Image maxW={'20px'} src={logoURL}/>
              <Image maxW={'20px'} src={logoURL}/>
              <Image maxW={'20px'} src={logoURL}/>
              <Image maxW={'20px'} src={logoURL}/>
            </SimpleGrid>
          </Center>

          <Center mt="24">
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
              <Heading fontSize={'5em'}>35K</Heading>
              <Text fontSize={'2em'}>Followers</Text>
            </Stack>
          </Center>

        </Box>
      </Container>
    </Box>
  );
}