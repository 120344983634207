import React, { useEffect, useState }from 'react';
import NavBar from '../components/Navbar';
import {
  Box,
} from '@chakra-ui/react';
import MediaKits from './MediaKits';

export default function Dashboard() {
  return (
    <Box backgroundColor="#F9FBFC;" pb="24">
      <NavBar />
      <MediaKits />
    </Box>
  );
}